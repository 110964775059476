@import "base";
.galleryContainer{
  width: 100%;
  .line{
    display: flex;
    .side{
      width:50%;
    }

  }
  .image{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-out;
    height: 480px; 
    @media screen and (max-width: $minWidth){       
      height: 350px; 
    } 
    @media screen and (max-width: $mobilewidth){       
      height: 250px; 
    }      
    cursor: pointer;
    background-color: #bbb;
  
    &:hover{
      .hover{
        transform: scale(1);
      }
    }
    .hover{
      position: relative;
      background-color: rgba(0, 0, 0, 0.52);
      height: 100%;
      text-align: center;
      transform: scale(0);
      transition: 0.2s ease-out;
      @media screen and (max-width: $minWidth){
        opacity:0;
        transform: scale(1);        
      }      
      span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color:white;
        img{
          height: 60px;
        }
      }
    }
/*     &.imgBig{
      height: 300px;
    }
    &.imgMedium{
      height: 200px;
    }
    &.imgSmall{
      height: 150px;
    } */
  }
}
#lightboxBackdrop {
  direction: ltr;
  font-family: opensans;
  button {
    background: transparent;
    min-width: 0;
    &:hover{
      opacity: .4;
    }
  }
}

.gallery-icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  background-position: -540px 0;
}


//new style:
.hotel-gallery{
  & > div {
    height: 350px;
    @media screen and (max-width: $minWidth){
      height: 200px;
    }        
  }
  .image {
    cursor: pointer;
    height: 100%;
    background-size: cover;
    background-position: center;    
    @media screen and (min-width: $minWidth + 1){
      border: 1px solid #fff;
    }       
    position: relative;
    .hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
.gallery-row {
  display: flex;
  & > div {
    flex: 1 1;
    &.large-item{
      flex: 4 4;
    }
  }
}
.gallery-column {
  @media screen and (max-width: $minWidth){
    display: none;       
  }    
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1 1;
  }
}
.more-images-text{
  display: none;
  color: #fff; 
}
.gallery-column:last-child > div:last-child {
  .hover{
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;    
    .more-images-text{
      display: block;
    }
  }
}
.react-images__dialog{
  .react-images__navigation button,button.react-images__header_button{
    background: transparent;
    border: 0 none;
    outline: none;
  }
}