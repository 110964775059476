@import "./base";
//colors:
$sunhotelRed: #b52555;
$sunhotelRedHover: #a92350;

.body.sunhotel-theme {
  &,
  .ticket-style-section:first-child::before,
  .ticket-style-section:first-child::after {
    background-color: #fff;
  }
  .cities-section,
  .newletter-section {
    background-color: #f1f1f1;
  }
  .bottom-footer {
    background-color: rgb(17, 50, 80);
    color: #fff;
  }
  .main-menu .menu-item.active a {
    color: $sunhotelRed;
    border-color: $sunhotelRed;
  }
  .button.red-btn {
    background-color: $sunhotelRed;
    &:hover {
      background-color: $sunhotelRedHover;
    }
  }
  .color-1-btn:not(:disabled) {
    background-color: $yellow;
    color: #fff;
    border-color: $yellow;
    &:active {
      background-color: $yellow;
    }
  }
  //   .home-search-section .search-form-wrapper {
  //     background-color: rgba(255, 255, 255, 0.15);
  //   }
  .purple {
    color: $sunhotelRed;
  }

  .home-search-section {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      @media (max-width: $minWidth){
        display: none;
      }
    }
    .home-top-slogan {
      text-shadow: 0 0 5px black;
    }
    & > div {
      position: relative;
    }
    @media (min-width: $minWidth+1) {
      .search-form-wrapper {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        );
      }
      .home-tabs-holder .home-tab {
        color: #333;
        background: linear-gradient(
          0,
          rgba(255, 255, 255, 0.65),
          rgba(255, 255, 255, 0.75),
          rgba(255, 255, 255, 0.9)
        );
        &.home-active-tab {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgb(255, 255, 255)
          );
          color: $sunhotelRed;
        }
      }      
    }
    .validator-label {
      background-color: $sunhotelRed;
      &::before {
        border-color: $sunhotelRed transparent transparent transparent;
      }
    }
    .remove-return-btn {
      background-color: $yellow;
    }
  }

  .Home {
    .search-form-wrapper .search-box-holder label,
    .add-destination-holder .link-style-btn {
      color: #000;
    }
    .search-form-wrapper .search-box-holder .triptype-radio-label {
      color: #444;
    }
    .triptype-radio-item .triptype-radio:checked + .triptype-radio-label {
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .color-1 {
    color: $yellow;
  }
  .blue-border-right {
    border-right-color: $yellow;
  }
  .radio-item {
    .radio-label .radio-mark {
      border-color: $yellow;
    }
    .custom-radio:checked + .radio-label .radio-mark:before {
      background-color: $yellow;
    }
  }
  .has-fake-magnifirer-icon:before {
    background-color: $yellow;
  }
  .PDatePicker-holder > div .calendarContainer .dayWrapper.today button,
  .PDatePicker-holder
    > div
    .calendarContainer
    .dayWrapper.today
    button[disabled],
  .detail-flight .stop-info.change-airport,
  .tab-holder .tab-s.active-tab-s {
    background-color: $sunhotelRed;
  }

  .main-content .ant-collapse-header .passenger-type-title {
    color: $yellow;
  }
  .Pagination .page-btn:not(.disabled) {
    color: #bbb;
    &:hover {
      color: #fff;
      background-color: $yellow;
    }
    &.clicked,
    &.clicked:hover {
      color: $yellow;
      background-color: #fff;
      background-color: transparent;
    }
  }
  // .mobile-tab-hotel-flight .home-tab .itours-icon {
  //   display: none;
  // }
}

.padide-com-theme {
  .search-result-page .selected-package{
    margin-top: 15px;
  }
  .package-hotel-footer .red-btn {
    background-color: #8bc34a;
  }
  .package-avails-row .package-hotel-item .package-hotel-footer{
    border-top-color: #f1f1f1;
    background-color: #f1f1f1;
    padding: 8px 15px;
  }
  &:not(.ltr) &{
    .package-hotel-main {
      min-height: 166px;
    } 
  }  
  &.ltr {  
    .package-hotel-main {
      min-height: 168px;
    } 
  }
  .multi-datepicker-input{
    background-image: url("../image/blue-calendar.svg");
  }
  &.body.ltr .multi-datepicker-input {
    background-position: left 7px top 6px;
  } 
  &.body:not(.ltr) .multi-datepicker-input {
    background-position: right 7px top 6px;
  } 
  
  .hotelResult .page-container.no-padding-mobile.search-result-page {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: $mobilewidth) {
    .room-guests.multyroom .room-number {
      color: inherit;
      display: inline-block;
      position: static;
      top: 0;
    }
  }

  .main-menu .itours-icon {
    display: none;
  }
  &.body {
    background-color: rgba(68, 110, 156, 0.12);
    .color-1-btn:not(:disabled) {
      background-color: $yellow;
      color: #fff;
      border-color: $yellow;
      &:active {
        background-color: $yellow;
      }
    }
    .loading-logo {
      width: 70px;
    }

    .navbar .logo-holder .logo {
      width: 70px;
      margin: auto;
      img {
        width: 100%;
        margin-top: 8px;
      }
    }
    .padideh-home-top {
      @media screen and (min-width: $minWidth + 1) {
        display: flex;
        padding: 40px 0;
      }
    }
    .adv-banner {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
      flex: 0 0 300px;
      cursor: pointer;
      background-size: cover;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.15);
      }
    }
    .newletter-section {
      background: #ffffff;
      padding: 35px 0;
    }
    .home-search-section {
      position: relative;
      @media screen and (min-width: $minWidth + 1) {
        min-height: calc(100vh - 323px);
        display: flex;
        justify-content: center;
        align-items: center;
        & > div{
          flex: 1 1;
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          0,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0)
        );
      }
    }
    .searchbox-inner {
      padding: 10px 0;
      @media screen and (min-width: $minWidth + 1) {
        padding: 15px 20px;
      }
    }

    .searchBar .ant-calendar-picker .anticon {
      background-image: url("../image/blue-calendar.svg");
      height: 22px;
      background-size: contain;
    }
    .search-box-holder .loading-circle.small {
      width: 31px;
      top: 2px;
      height: 31px;
    }

    .search-box-holder {
      @media screen and (max-width: $minWidth) {
        background-color: #fff;
        background-color: transparent;
      }
      .tab-holder {
        position: relative;
      }
    }

    .hotel-destination-holder .autocompleteText input {
      background-image: url("../image/blue-location.svg");
      background-size: 26px;
    }

    .bottom-footer {
      background-color: #404041;
      color: #fff;
      .footer-logo {
        display: none;
        // width: 100px;
        // margin-bottom: 10px;
        & + div {
          small {
            font-size: 13px;
          }
        }
      }
    }
    .form-group .form-control,
    .form-group .form_control,
    .form-group .ant-calendar-picker-input {
      box-shadow: none;
    }
    .result-inline-search-section {
      .children-ages {
        padding: 0;
        margin: 0;
      }
      .searchBtn {
        width: 100%;
        max-width: 100%;
        height: 35px;
        @media screen and (min-width: $minWidth + 1) {
          &.flight-search-btn {
            margin-top: 13px;
          }
          &.hotel-search-btn {
            margin-top: 17px;
          }
        }
      }
    }
    .flight .results-sorting-block {
      padding-top: 23px;
      padding-bottom: 5px;
      .sort-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        img {
          display: none;
        }
      }
    }
    .hotelResult .results-sorting-block {
      padding-top: 15px;
      padding-bottom: 2px;
    }
    .result-inline-search-section {
      & + .section-vertical-padding {
        padding-top: 10px;
      }
      .searchbox-inner {
        padding: 10px 0;
        .triptype-radio {
          margin-bottom: 0;
        }
      }
      @media screen and (min-width: $mobilewidth + 1) {
        background: #404041;
        label {
          color: #fff;
        }
        .radio-item {
          .radio-label .radio-mark {
            border-color: #fff;
          }
          .custom-radio:checked + .radio-label .radio-mark:before {
            background-color: #fff;
          }
        }
      }

      // @media screen and (max-width: $minWidth) {
      //   display: none;
      // }
      .search-box-holder {
        box-shadow: none;
        background: none;
        background: transparent;
      }
      .form-label {
        font-weight: 600;
        display: block !important;
      }
    }
    main.main-content {
      min-height: calc(100vh - 193px);
    }
    .airline-filter-holder {
      padding: 10px 10px;
      background-color: #fff;
      .cell {
        background-color: #f1f1f1;

        & + .cell {
          background-color: #f1f1f1;
          border-top: 1px solid #ddd;
        }
      }
    }

    //flight result:
    .avails-holder .flight-result-item {
      display: block;
      .item-main {
        display: block;
      }
      .item-footer {
        background: #f1f1f1;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        border: 0 none;
        .price {
          flex-direction: row;
          .value {
            margin-right: 8px;
          }
        }
        .button.red-btn {
          background-color: #8bc34a;
        }
      }
    }
    .flightDetails {
      .button.red-btn {
        background-color: #8bc34a;
      }
    }
    .filters-holder {
      // border: 1px solid #bcc7c4;
      // padding: 0 7px;
      // margin-top: 10px;
      // margin-right: 0 !important;
      // margin-left: 0 !important;
      // background: #fff;
      // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      .checkbox-label {
        .itours-icon {
          display: none;
        }
      }
      .ant-collapse-item .ant-collapse-header {
        padding-left: 10px;
        padding-right: 10px;
        &::after {
          display: none;
        }
      }
      .normal-title {
        padding: 0 10px;
        margin: 0;
      }
      .has-fake-magnifirer-icon {
        padding: 0 10px;
        margin-bottom: 0;
      }
      .filter-header {
        padding: 15px 0;
        margin: 0;
      }
    }
    .airline-table-holder {
      border: 0 none;
    }
    .airline-filter-row > div {
      flex: 0 0 160px;
    }
    .airline-filter-item {
      position: relative;

      img.airline-logo {
        position: absolute;
        width: 55px;

        font-size: 10px;
        line-height: 1;
        color: #bbb;
        overflow: hidden;
      }
    }
    .airline-table-header {
      position: relative;

      border: 1px solid #ccc;

      overflow: hidden;
      margin-top: 3px;
      .airline {
        position: absolute;

        height: 60px;
      }
    }
    .itours-tab-holder .itours-tab-list .itours-tab-btn.active {
      border: 2px solid #8bc34a;
      background-color: rgb(255, 255, 255);
      box-shadow: none;
    }
    .ant-steps {
      display: none;
    }
    .ticket-style-holder {
      border: 1px solid #ccc;
      .button.red-btn {
        background-color: #8bc34a;
      }
      .ticket-style-section {
        border: 0 none;
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
        &:first-child::before,
        &:first-child::after {
          display: none;
        }
      }
    }
    .icon-hint-holder {
      margin-bottom: 10px;
      .icon {
        display: none;
      }
      .hint-inner {
        color: inherit;
        background: transparent;
        padding: 0;
      }
    }
    .passenger-title span.itours-icon {
      display: none;
    }
    .hotel-result-item .hotel-image {
      margin: 0;
    }
    .has-fake-magnifirer-icon input.form-input {
      padding-left: 10px;
      padding-right: 10px;
    }
    .has-fake-magnifirer-icon {
      &::before,
      &::after {
        display: none;
      }
    }
    .hotel-result-item,
    .hotel-summary-card,
    .contact-us {
      .button.red-btn {
        background-color: #8bc34a;
      }
      .hotel-footer {
        border: 0 none;
      }
      .hotel-name {
        color: #8bc34a;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    .feature-list .feature-items div {
      color: #ff9800;
      padding: 0 5px;

      border: 1px solid #ffc107;
      font-size: 12px;
      line-height: 22px;
      border-radius: 10px;
    }
    .package-hotel-item {
      .package-hotel-details{
        .feature-list{
          margin-top: 3px;
          max-height: 20px;
          overflow: hidden;
        }
      }
      .feature-list .feature-items div {
        padding: 0 2px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 5px;
      }
    }
    .checkbox-holder {
      font-size: 14px;
    }
    .checkbox-label::before {
      width: 12px;
      height: 12px;
      border: 1px solid #bdbdbd;
      border-radius: 0;
    }
    .sidebar-item {
      padding: 10px 0;
      border: 0 none;
    }
    .hotelResult .button.results-sort-btn {
      min-width: 0;
      box-shadow: none;
      border: 0 none;
      background-color: transparent;
      &.disable {
        color: #555;
        &::after {
          border-top: 2px solid #555;
          border-right: 2px solid #555;
        }
      }
    }
    .hotel-result-item {
      background-color: #fff;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      display: flex;
      margin-bottom: 15px;
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .page-title {
      border: 0 none;
      padding-bottom: 0;
    }
    .result-page-search-bar .headerInformation {
      display: none;
    }
    .search-box-holder.inline .searched-information .fakeForm .textbox {
      border: 0 none;
      margin: 0;
    }
    .searched-information {
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .map-btn-holder {
      background-image: none;
      height: auto;
      display: block;
      .map-view-btn {
        width: 100%;
        border: 0 none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background: #ef4050;
        color: #fff;
        margin-bottom: 5px;
        margin-top: 10px;
      }
    }
    .searchShow {
      background: #fff;
      padding: 0 10px;
      margin-top: 10px;
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      .showSearch {
        display: none;
      }
    }
    .blue-border-right {
      border: 1px solid #ccc;
    }
    .hotel-detail-searchholder {
      display: none;
    }
    .room-select-card {
      margin-top: 20px;
      .button.red-btn {
        background-color: #8bc34a;
      }
      .col-small-12.col-medium-2.hidden-xsmall.hidden-small {
        display: none;
        & + .col-small-12 {
          width: 100%;
        }
      }
    }
    .hotel-head-info .button.red-btn {
      display: none;
    }
    .hotel-summary-card .hotel-head-info {
      display: block;
      .hotel-image.main-hotel-image {
        height: 170px;
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 0;
      }
    }
    .button.green-btn,
    .button[type="submit"].green-btn {
      background-color: #8bc34a;
    }
    main.main-content > .section-vertical-padding.bg-white {
      background-color: transparent;
    }
    .search-box-holder .loading-circle.small {
      &::after,
      &::before {
        top: 3px;
      }
    }
    .airline-autocomplete-holder .autocompleteText input {
      padding-top: 8px;
      & + .clear-autocomplete {
        right: 3px;
      }
    }
  }
  &.body:not(.ltr) {
    .adv-banner {
      background-image: url("../image/phuket.jpg");
      background-position: left bottom;
    }
    .autocompleteText .clear-autocomplete {
      left: 2px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
    .flight-origin-holder .autocompleteText input {
      background-image: url("../image/takeoff-blue-rtl.svg");
      background-position: right 5px top 4px;
      padding-top: 8px;
      background-size: 25px;
    }
    .flight-destination-holder .autocompleteText input {
      background-image: url("../image/landing-blue-rtl.svg");
      background-position: right 5px top 4px;
      background-size: 25px;
      padding-top: 8px;
    }
    .deleteable-route {
      padding-left: 40px;
      .delete-route {
        left: 0;
        top: 23px;
        position: absolute;
      }
    }
    .PDatePicker-holder .PDatePicker-input {
      background-position: right 5px top 4px;
      background-image: url("../image/blue-calendar.svg");
    }
    .hotel-destination-holder .autocompleteText input {
      background-position: right 5px top 4px;
    }
    .bottom-footer {
      .footer-logo {
        & + div {
          text-align: right;
        }
      }
    }
    .airline-filter-holder {
      .cell {
        border-right: 1px solid #ddd;
      }
    }
    .airline-filter-item {
      padding-right: 55px;
      img.airline-logo {
        right: 0;
      }
    }
    .airline-table-header {
      padding-right: 60px;
      border-radius: 0 10px 10px 0;

      .airline {
        right: 0;
      }
    }
    .feature-list .feature-items div {
      margin: 0 7px 5px 0;
    }
  }
  &.body.ltr {
    font-family: "sourceSans";
    .adv-banner {
      background-image: url("../image/phuket.jpg");
      background-position: left bottom;
    }
    .autocompleteText .clear-autocomplete {
      right: 2px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
    .flight-origin-holder .autocompleteText input {
      background-image: url("../image/takeoff-blue.svg");
      background-position: 5px 4px;
      background-size: 25px;
    }
    .flight-destination-holder .autocompleteText input {
      background-image: url("../image/landing-blue.svg");
      background-position: 5px 4px;
      background-size: 25px;
    }
    .deleteable-route {
      padding-right: 40px;
      .delete-route {
        right: 0;
        top: 23px;
        position: absolute;
      }
    }
    .hotel-destination-holder .autocompleteText input {
      background-position: 5px 4px;
    }

    .bottom-footer {
      .footer-logo {
        & + div {
          text-align: left;
        }
      }
    }
    .airline-filter-holder {
      .cell {
        border-left: 1px solid #ddd;
      }
    }
    .airline-filter-item {
      padding-left: 55px;
      img.airline-logo {
        left: 0;
      }
    }
    .airline-table-header {
      padding-left: 60px;
      border-radius: 10px 0 0 10px;

      .airline {
        left: 0;
      }
    }
    .feature-list .feature-items div {
      margin: 0 0 5px 7px;
    }
  }

}


.body.naminTravel-theme{
  .ticket-style-section:first-child:after, .ticket-style-section:first-child:before{
    background-color: #fff;
  }
  .newletter-section {
    .has-error .ant-form-explain, .has-error .ant-form-split {
      color: #ffc107;
    }
  }
  .newletter-section {
    background: linear-gradient(90deg, #f1f1f1, #714696);
  }  
  .itours-modal .modal-overlay,.full-container,.accountWindow,.loading-full-page {
    background: #124d6db3;
  }
  .navbar .logo-holder .logo {
    width: 200px;
    margin:0  auto;
  }
  // .loading-logo {
  //   width: 200px;
  // }
  main.main-content {
    min-height: calc(100vh - 347px);
    background: #fff;
  }

  .home-search-section.has-light-layer::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff54;
  }  
  .naminTravel-search-block {
    .search-form-wrapper, .mobile-tab-hotel-flight {
      position: relative;
      z-index: 1;
    }
  }
  .slider-text-inner-wrapper {
    @media (max-width:$mobilewidth){
      display: none;
    }
    @media (max-width:$minWidth){
      .slider-subtitle,.slider-title-text{
        color: #555;
        text-shadow: none;
      }
    }
    padding: 100px 0 30px;
    width: 650px;
    text-align: center;
    margin: 0 auto;
    .slider-subtitle,.slider-title-text{
      color: #fff;
      text-shadow: 1px 1px 5px #000000a8;
      transition: 0;
    }
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    .slider-title {
      font-size: 45px;
      font-weight: bold;
      .slider-title-text{
        position: absolute;
        bottom: 0;
        transition: all .2s linear .2s;
        left: 0;
        right: 0;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          bottom: 5px;
          transition: all .1s linear;
        }
      }      
    }
    .slider-subtitle {
      font-size: 25px;
      height: 30px;
      font-weight: 600;
      width: 100%;
      .slider-subtitle-text{
        position: absolute;
        bottom: 0;
        transition: all .2s linear .4s;
        left: 0;
        right: 0;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          bottom: 5px;
          transition: all .1s linear;
        }
      }      
    }
  }
  .adv-item {
    margin: 0 10px;
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    .adv-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, #000000db, transparent);
      font-weight: bold;
      color: #fff;
      font-size: 24px;
      text-align: center;
      padding: 25px 15px;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .namin-adv-section {
    background: #fff;
    padding: 35px 0;
    @media (max-width:$mobilewidth){
      display: none;
    }
  }
  .destination-section-title {
    color: #18a3ba;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
  }
  
  .namin-destinations-section {
    background-color: #f1f1f1;
    padding: 35px 0;
  }
  .namin-destinations {
    flex-wrap: wrap;
    @media (max-width:$mobilewidth){
      padding: 0;
      justify-content: center;
      .destination-block{
        flex-basis: 50% !important;
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        margin-bottom: 25px;
        &:nth-child(2n):after{
          display:none  !important;
        }
      }
    }
    @media (min-width:$mobilewidth+1) and (max-width:$minWidth){
      padding: 0;
      justify-content: center;
      .destination-block{
        flex-basis: 33% !important;
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        margin-bottom: 25px;
        &:nth-child(3n):after{
          display:none  !important;
        }
      }
    }
    display: flex;
    padding: 0 50px; 
    margin: 50px 0;
    .destination-block {
      flex: 1;
      position: relative;
      &:not(:last-child)::after{
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        top: 25px;
        bottom: 0;
        background-color: #c6c6c6;
        width: 1px;
      }
      .block-title {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        color: #717171;
      }
      .dest-country-title{
        font-weight: 600;
        font-size: 18px;
        color: #535353;
        margin-bottom: 0;
      }
    }
    .dest-item-link {
      display: block;
      color: #888;
      line-height: 20px;
    }
    .dest-country-item {
      margin-bottom: 15px;
      padding: 0 15px ;
    }
  }
  .home-search-section {
    .slider-next-btn,.slider-prev-btn{
      padding: 0 ;
      flex-shrink:0;
    }
  }
  .slider-next-btn,.slider-prev-btn,.namin-adv-section .slick-arrow {
    line-height: 30px;
      height: 30px;
      width: 30px;
    background-color: #fff;
    cursor: pointer;
    &:hover{
      background-color: #f1f1f1;
    }
    min-width: 0;
    border-radius: 50%;
    border: 0 none !important;
    padding: 5px;
    box-shadow: 0 0 7px 1px #0000004d;
    outline: none !important;
    img{
      width: 15px;
    }
    &.slick-disabled{
      opacity: 0;
      visibility: hidden;
    }
  }
  .slider-subtitle-and-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .namin-adv-section .slick-slider {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    .slick-arrow{
      position: absolute;
      top: 50%;
      width: 25px;
      height: 25px;
      z-index: 1;
      margin-top: -13px;
      &.slick-prev{
        left: -3px;
      }
      &.slick-next{
        right: -3px;
      }
    }
  
  }
  
  .naminTravelHeader {
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
  }
  .namin-flags-holder{
    @media (max-width:$mobilewidth){
      display: none;
    }
    img {
     width: 40px;
     margin: 0 5px;
     border: 1px solid #eee;
     @media (max-width:$maxWidth){
      width: 30px;
    }
   }
  }
  .namin-login-currency-holder .itours-select{
    border: 0 none;
    outline: none !important;
    background-image: none;
    cursor: pointer;
  }

  .namin-logo-holder {
    max-width: 220px;
    @media (max-width:$minWidth){
      max-width: 180px;
    }
    a{
      @media (min-width:$mobilewidth+1){
        display: block;
      }
      img{
        max-width: 100%;
      }
    }
  }
  @media (max-width:$mobilewidth){
    .logo +.menu-toggle-btn {
      position: absolute;
      left: 10px;
    }
    .namin-login-currency-holder > div{
      position: absolute;
      
      right:0;
      &:not(.account-links){
        top: 0;
      }
      &.account-links{
        bottom: 5px;
        right: 15px;
        a.link-style-btn{
          display: block;
          margin-bottom: -6px;
        }
      }
    }
    .namin-logo-holder {
      margin: auto;
    }
  }

  .main-menu .menu-item{
    margin:0 10px;
  }
  .navbar{
    padding-bottom: 0;
  }
  .ballonContainer{
    float: none;
  }
}
.accountWindow {    
  .Form.naminTravel-register-form  {
    @media (min-width:$mobilewidth+1){
      max-width: 650px;
      .form-group {
        .form-label{
          width: 150px;
          display: inline-block;
        }
        .form-input,.form-select,.select-input-pair-holder-outer{
          display: inline-block;
          width: calc(100% - 150px);
        }
      }
      button.button.full-width {
        width: calc(100% - 150px);
      }
      .validator-label{
        body:not(.ltr) & {
          margin-right: 150px;
        }
        body.ltr & {
          margin-left: 150px;
        }
      }
      
    }
  }
}






