
/* .deleteable-route {
    @media screen and (min-width:$mobilewidth +1 ) {
        position: relative;
        
        body:not(.ltr) &{
            padding-left: 100px;
        }
        body.ltr &{
            padding-right: 100px;
        }  
        .delete-route.button {
            position: absolute;
            body:not(.ltr) &{
                left: 0;
            }
            body.ltr &{
                right: 0;
            }              
            top: 25px;
            width: 85px;
        }   
      }
} */

.flight-direction-arrow img {
    margin: 0 3px;
    width: 24px;
    display: block;
}

.airline-autocomplete-holder .form-control {
    padding: 0 10px !important;
}
.stopInfo{

    .airplaneIcon{
        padding-top: 18px;
    }
    img{
        height: 16px;
    }
    div{
        div:first-child{
            border-bottom:1px dotted gray;
        }
        padding-top: 2px;
        height: 28px;
        .duration{
            font-size: 13px;
            color:#b7b5b5;
        }
    }
    /* display: flex;
    justify-content: center; */
}
//
.page-container{
    &.flight{
        display: flex;
    }
}
.detail-btn-holder-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;

    @media (max-width:$minWidth){
        flex-direction: row-reverse;
        justify-content: space-between !important;
        position: static;
        width: 100%;
        .included-baggage-mark{
            transform: translate(0) !important;
        }
    }
}
.flightdetails{
    .flightAside{
        padding-bottom: 10px;
        .route-item{
            min-height: 0;
            .airline-logo-holder{
                margin-left: -5px;
            }
            .router{
                line-height: 20px;
                .originInfo,.destinationInfo{
                    padding-top: 0;
                    .codeCity,.time{
                        font-size: 13px;
                    }
                }
                .stopInfo .airplaneIcon{
                    padding-top: 12px;
                }
            }
        }
    }
    aside{
        .normal-title{
            font-weight: 600;
            font-size: 20px;
            color: rgb(58,58,58);
        }
    }
    .prices-info{
        .ant-collapse-borderless{
            background-color: transparent;
        }

    }
    .totalPrice{
        .button{
            margin-top: 15px;;
        }
        &.inli{
            .priceCur{
                border-top: 1px solid #ccc;
                padding-top: 15px;
                .price{
                    font-size: 18px;
                }
            }
            padding-top: 20px ;
            b{
                padding-top: 4px;
            }
        }
    }
}

.ticket-style-holder{
    overflow: hidden;
}
.ticket-style-section{
    padding: 20px 0;
    .pair-row{
        display: flex;
        justify-content: space-between;
    }
    border: 1px dashed rgb(204,204,204);
    position: relative;
    background-color: #fff;
    &:first-child{
        border-bottom: 0 none;
        padding-bottom: 15px;
        &::before{
        content: "";
        display: block;
        position: absolute;
        background: #f4f6f8;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        bottom: -9px;
        z-index: 1;
        border: 1px dashed #bbb;
        left: -9px;               
        }
        &::after{
        content: "";
        display: block;
        position: absolute;
        background: #f4f6f8;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        bottom: -9px;
        z-index: 1;
        border: 1px dashed #bbb;
        right: -9px;               
        }
    }
}

.passenger-header.card-padding{
    font-weight: 800;
}
.ant-collapse-header{
    font-weight: 600;
}
.flight-result-item{
    .flight-price-block{
        color:#464545;
        font-size: 26px;
        padding-bottom: 6px;
    }
}
.hotelbook{
    font-size: 12px;
    img{
        height: 22px;
        transition: all 0.4s ease-in;
        &.rot{
            transform: rotate(180deg);
        }

    }
}
.search-box{
    >div{
        &:first-child{
            width: 100%;

        }
    }
}

//airline filter:
.airline-filter-holder{
    .cell {
        background: #e9efeb;
        white-space: nowrap;
        height: 25px;
        padding: 0 5px;
        .checkbox-label{
            line-height: 24px;
        }
        &+ .cell{
            background-color: #d7e0dd;
        }
    }
    .airline-table-header {
        text-align: center;
        @media screen and (min-width:$mobilewidth +1 ){
            min-width: 100px;
        }
        .airline {
            padding: 0 10px;
            background-color: #fff;
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: center;
        } 
        .cell{
            line-height: 25px;
        }
    }    
}
.airline-table-holder {
    overflow-x: auto;
    border: 1px solid #ccc;
}
.airline-filter-row{
    display: flex;
    flex-wrap: nowrap;
    & > div{
        flex: 0 1 126px;
    }
}
.airline-filter-item{
    @media screen and (min-width:$mobilewidth +1 ){
        min-width: 120px;
    }
    text-align: center;
    margin: 3px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;    
    .airline-logo{
        height: 45px;
        max-width: 100px;
        object-fit: contain;
        padding: 5px;
        display: block;
        margin: auto;
    }

}
.diff-days {
    position: absolute;
    left: 100%;
    top: -10px;
    direction: ltr;
}