@import "base";
.itours-icon {
  background-image: url("../image/icons.svg");

  //as IE dosnt support svg background:
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-image: url("../image/icons.png");
  }
  background-size: 3200% 600%;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  &.icon-small {
    width: 17px;
    height: 17px;
  }
  &.icon-xsmall {
    width: 12px;
    height: 12px;
  }
  &.icon-large {
    width: 40px;
    height: 40px;
  }
  &.icon-xlarge {
    width: 70px;
    height: 70px;
  }
  &.icon-xxlarge {
    width: 100px;
    height: 100px;
  }
  &.error-icon {
    background-position: -61.3% 40%;
  }
  &.error2-icon {
    background-position: -64.5% 40%;
  }
  &.top-plane-white {
    background-position: 0 0;
  }
  &.top-plane-blue {
    background-position: 0 60%;
  }
  &.top-plane-gray {
    background-position: 0 20%;
  }
  &.hotel-white {
    background-position: 3.2% 0;
  }
  &.hotel-blue {
    background-position: 3.2% 60%;
  }
  &.hotel-gray {
    background-position: 3.2% 20%;
  }
  &.hotel-dark {
    background-position: 29% -20%;
  }
  &.checkin-dark {
    background-position: 19.3% -20%;
  }
  &.checkout-dark {
    background-position: 22.6% -20%;
  }
  &.white-clock-icon {
    background-position: 16.1% -20%;
  }
  &.gray-clock-icon {
    background-position: 16.1% 20%;
  }
  &.red-clock-icon {
    background-position: 16.1% 40%;
  }
  &.moon-dark {
    background-position: 32.4% -20%;
  }
  &.location-dark {
    background-position: 25.8% -20%;
  }
  &.guest-dark {
    background-position: 9.7% -20%;
  }
  &.close-icon {
    background-position: 67.78% 0;
  }
  &.gray-close-icon {
    background-position: 67.78% 20%;
  }
  &.white-close-icon {
    background-position: 67.78% 0;
  }
  &.calendar-dark {
    background-position: 35.4% -20%;
  }
  &.right-arrow-dark {
    background-position: 74.2% -20%;
  }
  &.telegram {
    background-position: -9.7% 20%;
  }
  &.twitter {
    background-position: -6.4% 20%;
  }
  &.facebook {
    background-position: -19.3% 100%;
  }
  &.facebook-gray{
    background-position: 67.78% 100%;
  }
  &.instagram {
    background-position: -12.9% 20%;
  }
  &.circle-error {
    background-position: -12.9% 60%;
  }
  &.whatsapp {
    background-position: -3.2% 0;
  }
  &.check-green {
    background-position: -9.7% 60%;
  }
  &.check-white {
    background-position: -9.7% 100%;
  }
  &.peyment-warning {
    background-position: -12.9% 0;
  }
  &.peyment-error {
    background-position: -16.1% 0;
  }
  &.peyment-ok {
    background-position: -6.4% 0;
  }
  &.peyment-exchange {
    background-position: -9.7% 0;
  }
  &.adult {
    background-position: 0 -20%;
  }
  &.child {
    background-position: 3.2% -20%;
  }
  &.infant {
    background-position: 6.4% -20%;
  }
  &.empty-moon {
    background-position: 38.7% -20%;
  }
  &.fill-moon {
    background-position: 41.9% -20%;
  }
  &.empty-sun {
    background-position: 45.1% -20%;
  }
  &.fill-sun {
    background-position: 48.4% -20%;
  }
  &.user-profile {
    background-position: 51.6% 60%;
  }
  &.user-login {
    background-position: 80.6% 100%;
  }
  &.hotel-ticket {
    background-position: -3.2% 60%;
  }
  &.flight-ticket {
    background-position: -16.1% 60%;
  }
  &.change-password {
    background-position: 54.8% 60%;
  }
  &.checkmark-a {
    background-position: 54.8% 0;
  }
  &.checkmark-b {
    background-position: 54.8% 20%;
  }
  &.red-email-icon {
    background-position: 22.62% 40%;
  }
  &.red-phon-icon {
    background-position: 32.3% 40%;
  }
  &.gray-phon-icon {
    background-position: 32.3% 40%;
  }
  &.red-address-icon {
    background-position: 25.8% 40%;
  }
  &.about-us {
    background-position: 58.1% -20%;
  }
  &.contact-us {
    background-position: 51.6% -20%;
  }
  &.terms {
    background-position: 54.8% -20%;
  }
  &.plus-icon {
    background-position: -9.7% 40%;
  }
  &.plus-simple-icon {
    background-position: -12.9% 80%;
  }
  &.minus-icon {
    background-position: -12.9% 40%;
  }
  &.search-icon {
    background-position: -25.8% 100%;
  }
  &.red-arrow-right {
    background-position: 74.2% 40%;
  }
  &.breakfast-icon {
    background-position: 61.3% -20%;
  }
  &.info-icon {
    background-position: 96.7% -60%;
  }
  &.red-info-icon {
    background-position: 96.7% -20%;
  }
  &.green-info-icon {
    background-position: 96.7% -40%;
  }
  &.green-checkmark {
    background-position: 64.5% -20%;
  }
  &.fork-knife-circle {
    background-position: -12.9% 100%;
  }
  &.baggage-circle {
    background-position: -16.1% 100%;
  }
  &.package-white {
    background-position: 6.4% 0;
  }
  &.package-blue {
    background-position: 6.4% 60%;
  }
  &.package-gray {
    background-position: 6.4% 20%;
  }
  &.package-dark {
    background-position: 6.4% -20%;
  }
  &.help-icon{
    background-position: -3.2% 100%;
  }
}
