@import "base";

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
.loading {
  //background-color: #fff;
  /* position: fixed;
    max-width: 450px; */
  min-height: 300px;
  /*     top: 50%;
    left: 50%; */
  //    z-index: 1000;
  width: 100%;
  /*     -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */

  display: flex;
  justify-content: center;
  align-items: center;

  & + .overlay {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  /*     .content{
        font-size: 20px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: white;
    } */
}
.loading-circle {
  &.small {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    top: 3px;
    margin: 0;
    right: 5px;

    &::before,
    &::after {
      border-width: 3px;
      top: 2px;
      height: 25px;
      width: 25px;
      right: 2px;
    }
    .search-box-holder & {
      height: 40px;
      &::before,
      &::after {
        top: 5px;
      }
      .detail-search-holder & {
        height: 30px;
        top: 2px;
        body.ltr & {
          left: 5px;
          right: auto;
        }
        & + input {
          transition: none;
          body:not(.ltr) & {
            padding-right: 40px;
            direction: rtl;
          }
          body.ltr & {
            padding-left: 40px;
          }
        }
      }
    }
  }
  width: 100px;
  height: 100px;
  position: relative;
  margin: -10px auto 20px;
  &::before {
    content: "";
    display: block;
    border: 8px solid #fff;
    width: 100%;
    height: 100%;
    @include border-radius(50%);
    position: absolute;
    box-sizing: border-box;
  }
  &::after {
    content: "";
    display: block;
    border: 8px solid;
    border-color: #004ba8 transparent transparent transparent;
    width: 100%;
    height: 100%;
    @include border-radius(50%);
    position: absolute;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    box-sizing: border-box;
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.dots-loading {
  margin: 0 5px;
  .dot {
    -webkit-animation: dots 0.9s infinite linear;
    animation: dots 0.9s infinite linear;
    font-size: 17px;
    margin: 0 1px;
    font-weight: bold;
    &:nth-child(2) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 6s;
      animation-delay: 0.6s;
    }
  }
}
@-webkit-keyframes dots {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dots {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading-text {
  font-size: 16px;
  b,
  strong {
    font-weight: 600;
  }
}

$color-4: #eead68;
$color-3: #eec368;
$color-2: #eece68;
$color-1: #eed968;
$color-5: #ee8c68;

$color: $color-1, $color-2, $color-3, $color-4, $color-5;

@mixin anim() {
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
    75% {
      transform: scale(2.5);
    }
    78%,
    100% {
      opacity: 0;
    }
  }
}

.loading-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  overflow: hidden;
  animation-delay: 1s;
  padding: 0 10px;
  @for $i from 1 through 5 {
    .item-#{$i} {
      width: 20px;
      height: 20px;
      background: #f583a1;
      border-radius: 50%;
      @include anim();
      background-color: nth($color, $i);
      margin: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: nth($color, $i);
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 200ms * $i;
        transition: 0.5s all ease;
        transform: scale(1);
      }
    }
  }
}
.loading-logo {
  width: 120px;
  display: block;
  margin: auto;
}
.loading-outer-container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
